export const m ={
    menu_home: '首頁',
    menu_product: '產品',
    menu_features: '特點',
    menu_advantage: '優勢',
    menu_login: '登入量化',
    menu_language: '語言',
    banner_title: '方圓AI智能量化系統',
    banner_text: '方圓量化是提供線上數字貨幣交易的輔助平台, 產品主要為數字貨幣交易策略.',
    product_title: '方圓AI智能量化軟件',
    product_text_p1: '方圓AI智能量化軟件原為國際大型投資機構歷時多年開發的專屬金融量化工具, 目前應市場所需, 改進為可為終端客戶使用的專為數字貨幣智能交易的掌上應用.',
    product_text_p2: '量化交易本是全球各大基金和投資機構自己研發和使用的秘密武器, 也是未來發展大勢所趨, 隨著現代金融科技不斷發展近年來之前用於外彙和股票期貨操作的量化交易軟件開始應用於數字貨幣交易, 並在2021年開始步入了全面衝刺加速狀態.',
    product_text_p3: '量化交易作為國際投資界興起的一個新理念發展勢頭迅猛, 引起諸多投資者尤其是加密數字資產投資機構的關注.',
    product_text_p4: '與人為分析決策相比, 量化交易帶來了一種非主觀性的投資思路, 一種不受客觀情緒干擾的另類的操作方法.',
    product_text_p5: '通過對投資過程的程序化, 把人的情緒排除到投資進程之外, 量化交易將以其低風險、收益穩定的特性成為未來的機構投資者的主要投資策略.',
    features_title: '方圓AI智能量化核心功能特色',
    features_subtitle1: 'AI智能趨勢策略',
    features_subtext1: '平台智能選幣, AI分析趨勢智能開倉和補倉, 多種止盈機制, 強大的自定義策略功能, 多幣種7*24小時同時量化.',
    features_subtitle2: '邀請好友分傭',
    features_subtext2: '強大的會員系統和分潤機制, 邀請好友加入幣贏量化, 即可獲得好友盈利的利潤分成, 一起分享賺錢的喜悅!',
    features_subtitle3: '量化賬戶資金安全',
    features_subtext3: '用戶入金到交易所, 平台只需接入交易所API即可, 無需把資金入賬到平台, 最大程度保證平台用戶的資金安全.',
    advantage_title: '方圓AI智能量化優勢',
    advantage_cardtitle1: '智能選幣',
    advantage_cardtext1: '由方圓AI智能趨勢量化軟件根據全球市值排行與交易活躍程度自動甄選當日可交易幣種, 幫助客戶極大地降低自行選幣的風險.',
    advantage_cardtitle2: '智能建倉',
    advantage_cardtext2: '方圓AI智能趨勢量化軟件獨創建倉策略, 根據用戶資金合理配置首單倉位, 利用AI分析趨勢智能化完成建倉操作.',
    advantage_cardtitle3: '智能補倉',
    advantage_cardtext3: '方圓AI智能趨勢量化軟件提供 保守、穩健、激進 三種風格的補倉策略, 也可根據用戶自定義補倉策略, AI智能分析在合理的價位進行補倉操作.',
    advantage_cardtitle4: '防止瀑布和插針',
    advantage_cardtext4: '方圓AI智能趨勢量化軟件會自動識別當前幣價是否出現瀑布式下跌或者交易所插針的情況, 避免無謂的補倉, 降低資金損耗.',
    advantage_cardtitle5: '追踪止盈',
    advantage_cardtext5: '方圓AI智能趨勢量化軟件有多種止盈模式, 當止盈點位達到時, 如果行情一直持續, AI會一直持有並在後續合理價位上止盈, 幫助用戶獲取到最大利潤.',
    advantage_cardtitle6: '解放時間',
    advantage_cardtext6: '方圓AI智能趨勢量化軟件只需用戶接入交易所API, 便能多幣種7*24小時不間斷進行同時量化, 用戶無需單獨配置服務器運行策略機器人.',
};