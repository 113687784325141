export const m ={
    menu_home: 'Beranda',
    menu_product: 'Produk',
    menu_features: 'Fitur',
    menu_advantage: 'Keuntungan',
    menu_login: 'Masuk',
    menu_language: 'Bahasa',
    banner_title: 'Sistem perdagangan kuantitatif FYLH AI',
    banner_text: 'FYLH Quantitative adalah platform tambahan yang menyediakan transaksi cryptocurrency. Sistem ini menggunakan algoritme kecerdasan buatan untuk secara otomatis mencocokkan strategi perdagangan kuantitatif yang sesuai dengan situasi pasar saat ini.',
    product_title: 'Sistem perdagangan kuantitatif cerdas FYLH AI',
    product_text_p1: 'Perangkat lunak kuantitatif cerdas FYLH AI pada awalnya merupakan alat kuantitatif keuangan eksklusif yang dikembangkan oleh lembaga investasi internasional besar selama bertahun-tahun. Saat ini dalam menanggapi kebutuhan pasar dan telah ditingkatkan menjadi aplikasi genggam untuk transaksi cerdas cryptocurrency yang dapat digunakan oleh para pelanggan.',
    product_text_p2: 'Perdagangan kuantitatif adalah senjata rahasia yang dikembangkan dan digunakan oleh dana besar dan lembaga investasi di seluruh dunia, dan juga merupakan tren umum perkembangan masa depan. Dengan kelanjutan perkembangan teknologi keuangan modern, perangkat lunak perdagangan kuantitatif yang digunakan untuk pertukaran valuta asing dan saham dalam beberapa tahun terakhir telah mulai diterapkan pada cryptocurrency. Trading, dan telah memasuki status akselerasi penuh kecepatan pada tahun 2021.',
    product_text_p3: 'Perdagangan kuantitatif, sebagai konsep baru yang muncul di komunitas investasi internasional, telah berkembang pesat, menarik perhatian banyak investor, terutama lembaga investasi aset kripto.',
    product_text_p4: 'Dibandingkan dengan analisa perorangan dan pembuatan keputusan, perdagangan kuantitatif membawa pemikiran investasi non-subyektif, dengan  metode operasi alternatif yang tidak terganggu oleh emosi objektif.',
    product_text_p5: 'Melalui prosedur dari proses investasi untuk mengesampingkan emosi perorangan dari proses investasi, perdagangan kuantitatif akan menjadi strategi investasi utama investor institusi masa depan karena karakteristik pengembaliannya yang berisiko rendah dan stabil.',
    features_title: 'Fitur inti dari sistem perdagangan kuantitatif cerdas FYLH AI',
    features_subtitle1: 'Trending Stategi Kepintaran AI',
    features_subtext1: 'Platform pilihan cryptocurrency cerdas tren analisis AI, pembukaan cerdas dan margin panggilan, beberapa mekanisme target keuntungan, fungsi strategi khusus yang kuat, perdagangan kuantitatif multi-cryptocurrency 24 jam sehari 7 Hari seminggu pada saat yang sama.',
    features_subtitle2: 'Program Afiliasi',
    features_subtext2: 'Sistem keanggotaan yang kuat dan mekanisme afiliasi. Ajak teman bergabung dengan The FYLH, kamu bisa mendapatkan pembagian keuntungan dari keuntungan teman, dan berbagi kegembiraan dengan menghasilkan uang bersama.',
    features_subtitle3: 'Keamanan Dana',
    features_subtext3: 'Bagi pengguna untuk menyetor dana ke bursa (Huobi dan Binance), The FYLH hanya perlu mengakses API pertukaran, untuk memaksimalkan keamanan dana pengguna.',
    advantage_title: 'Keuntungan menggunakan The FYLH',
    advantage_cardtitle1: 'Cryptocurrency pintar dipilih',
    advantage_cardtext1: 'FYLH secara otomatis memilih cryptocurrency yang dapat diperdagangkan pada hari itu sesuai dengan peringkat nilai pasar global dan aktivitas transaksi, membantu pelanggan mengurangi risiko dalam memilih mata uang mereka sendiri.',
    advantage_cardtitle2: 'Posisi terbuka yang cerdas',
    advantage_cardtext2: 'FYLH memiliki strategi pembuatan posisi mandiri, dan secara rasional mengalokasikan posisi pertama sesuai dengan dana pengguna, dan menggunakan AI untuk menganalisis tren untuk menyelesaikan operasi pembukaan secara cerdas.',
    advantage_cardtitle3: 'Panggilan margin cerdas',
    advantage_cardtext3: 'FYLH menyediakan tiga gaya strategi margin call: konservatif, kuat, dan agresif, dan juga dapat disesuaikan menurut strategi margin call yang ditentukan pengguna, dan analisis cerdas AI melakukan operasi pembelian dengan harga yang wajar.',
    advantage_cardtitle4: 'Cegah harga turun dan pin',
    advantage_cardtext4: 'FYLH akan secara otomatis mengidentifikasi apakah harga cryptocurrency saat ini memiliki sedang turun atau pin pertukaran dimasukkan, menghindari margin call yang tidak perlu dan mengurangi kerugian modal.',
    advantage_cardtitle5: 'Lacak Ambil-Untung',
    advantage_cardtext5: 'FYLH memiliki berbagai mode Ambil-Untung. Ketika titik Ambil-Untung tercapai, jika pasar terus berlanjut, AI akan selalu menahan dan mengambil keuntungan pada harga yang wajar berikutnya untuk membantu pengguna mendapatkan keuntungan maksimal.',
    advantage_cardtitle6: 'Bebaskan waktumu',
    advantage_cardtext6: 'FYLH hanya mengharuskan pengguna untuk mengikat API pertukaran Huobi dan Binance, memilih mata uang kripto yang sesuai, gaya strategi yang sesuai, dan memulai hanya dengan sekali klik, dan kemudian mereka dapat mewujudkan manajemen keuangan kuantitatif yang cerdas.',
};