export const m ={
    menu_home: 'Home',
    menu_product: 'Product',
    menu_features: 'Features',
    menu_advantage: 'Advantage',
    menu_login: 'Login',
    menu_language: 'Language',
    banner_title: 'FYLH AI quantitative trading system',
    banner_text: 'FYLH is an auxiliary platform that provides cryptocurrency transactions. The system uses artificial intelligence algorithms to automatically match quantitative trading strategies that are suitable for the current market situation.',
    product_title: 'FYLH AI intelligent quantitative trading system',
    product_text_p1: 'The FYLH AI intelligent quantitative software was originally an exclusive financial quantitative tool developed by large international investment institutions for many years. It is currently in response to market needs and has been improved into a handheld application for cryptocurrency intelligent transactions that can be used by end customers.',
    product_text_p2: 'Quantitative trading is a secret weapon developed and used by major funds and investment institutions around the world, and it is also the general trend of future development. With the continuous development of modern financial technology, quantitative trading software used for foreign exchange and stock futures operations in recent years has begun to be applied to cryptocurrencies. Trading, and has entered a full sprint acceleration state in 2021.',
    product_text_p3: 'Quantitative trading, as a new concept that has emerged in the international investment community, has developed rapidly, attracting the attention of many investors, especially crypto asset investment institutions.',
    product_text_p4: 'Compared with human analysis and decision-making, quantitative trading brings a non-subjective investment thinking, an alternative operation method that is not disturbed by objective emotions.',
    product_text_p5: 'Through the proceduralization of the investment process to exclude people\'s emotions from the investment process, quantitative trading will become the main investment strategy of future institutional investors due to its low-risk and stable return characteristics.',
    features_title: 'Core features of FYLH AI intelligent quantitative trading system',
    features_subtitle1: 'AI intelligent trending strategies',
    features_subtext1: 'Platform intelligent cryptocurrency selection, AI analysis trend intelligent opening and call margin, multiple target profit mechanisms, powerful custom strategy functions, 7*24 hours multi-cryptocurrency quantitative tradings at the same time.',
    features_subtitle2: 'Affiliate Program',
    features_subtext2: 'Powerful membership system and affiliate mechanism. Invite friends join The FYLH, you can get the profit sharing of friends\' profit, and share the joy of making money together.',
    features_subtitle3: 'Fund Security',
    features_subtext3: 'For users to deposit funds to the exchange(Huobi and Binance), The FYLH only needs to access the exchange API, to maximize the security of users\' funds.',
    advantage_title: 'The FYLH Advantage',
    advantage_cardtitle1: 'Smart cryptocurrency choosen',
    advantage_cardtext1: 'The FYLH automatically selects thecryptocurrencies that can be traded on the day according to the global market value ranking and transaction activity, helping customers greatly reduce the risk of choosing their own currencies.',
    advantage_cardtitle2: 'Smart open position',
    advantage_cardtext2: 'The FYLH has an independent position creation strategy, and rationally allocates the first position according to the user\'s funds, and uses AI to analyze the trend to intelligently complete the opening operation.',
    advantage_cardtitle3: 'Smart margin call',
    advantage_cardtext3: 'The FYLH provides three styles of margin call strategies: conservative, robust and aggressive, and can also be customized according to user-defined margin call strategies, and AI intelligent analysis performs buy in operations at a reasonable price.',
    advantage_cardtitle4: 'Prevent waterfall and pin',
    advantage_cardtext4: 'The FYLH will automatically identify whether the current cryptocurrency price has a waterfall or an exchange pin is inserted, avoiding unnecessary margin call and reducing capital loss.',
    advantage_cardtitle5: 'Take-Profit Tracking',
    advantage_cardtext5: 'The FYLH has a variety of take-profit modes. When the take-profit point is reached, if the market continues, AI will always hold and take profit at a subsequent reasonable price to help users get the maximum profit.',
    advantage_cardtitle6: 'Free your time',
    advantage_cardtext6: 'The FYLH only requires users to bind the Huobi and Binance exchange APIs, select the corresponding cryptocurrency, the corresponding strategy style, and one-click start, and then they can realize intelligent quantitative financial management.',
};