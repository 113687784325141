import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        'en': require('../common/lang/en'),
        'id': require('../common/lang/id'),
        'zhHant': require('../common/lang/zhHant')
    }
})

export default i18n