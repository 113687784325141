<template>
  <v-app>
    <v-app-bar elevation="0" app light clipped-left>
      <!-- <div class="d-flex align-center">
        <v-img class="shrink mr-2" contain src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" />
      </div> -->
      <div class="d-flex align-center"><h1 class="primary--text">FYLH</h1></div>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down mr-4">
        <v-row>
          <v-col cols="2" class="text-align">
            <v-btn href="#home" text>
              <span>{{$t('m.menu_home')}}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn href="#product" text>
              <span>{{$t('m.menu_product')}}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn href="#features" text>
              <span>{{$t('m.menu_features')}}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn href="#advantage" text>
              <span>{{$t('m.menu_advantage')}}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn href="/app/" text>
              <span>{{$t('m.menu_login')}}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-menu open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  {{$t('m.menu_language')}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group v-model="lang" @change="changeLang()">
                  <v-list-item>
                    <v-list-item-title>English</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Indonesia</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>繁體中文</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <div class="d-md-none">
        <v-btn href="/app/" class="primary mr-4">{{$t('m.menu_login')}}</v-btn>
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-translate</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="lang" @change="changeLang()">
              <v-list-item>
                <v-list-item-title>English</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Indonesia</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>繁體中文</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary clipped>
      <v-list nav>
        <v-list-item-group v-model="group">
          <v-list-item>
            <v-list-item-title>
              <v-btn href="#home" text plain>
                <span>{{$t('m.menu_home')}}</span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn href="#product" text plain>
                <span>{{$t('m.menu_product')}}</span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn href="#features" text plain>
                <span>{{$t('m.menu_features')}}</span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn href="#advantage" text plain>
                <span>{{$t('m.menu_advantage')}}</span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <section id="home">
        <v-img :min-height="minHeight" :src="require('@/assets/banner.png')" class="white--text">
          <v-container class="fill-height px-4 py-12">
            <v-responsive class="d-flex align-center mx-auto" height="100%" width="100%">
              <v-row>
                <v-col class="d-flex align-center">
                  <v-card color="rgba(255, 255, 255, 0)" flat>
                    <v-card-title class="white--text">{{$t('m.banner_title')}}</v-card-title>
                    <v-card-text class="white--text">{{$t('m.banner_text')}}</v-card-text>
                    <v-card-actions>
                      <v-btn rounded><v-icon class="mr-2">mdi-apple</v-icon>Apple</v-btn>
                      <v-btn rounded href="/fylh.apk"><v-icon class="mr-2">mdi-android</v-icon>Android</v-btn>
                      <v-btn rounded href="/app/"><v-icon class="mr-2">mdi-language-html5</v-icon>Html5</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="4" class="align-center hidden-sm-and-down">
                  <v-img :src="require('@/assets/app.png')"></v-img>
                </v-col>
              </v-row>
            </v-responsive>
          </v-container>
        </v-img>
      </section>
      <section id="product" class="pt-16">
        <v-container class="fill-height px-4 py-12">
          <v-responsive class="d-flex align-center mx-auto" height="100%" width="100%">
          <v-row>
            <v-col cols="12" md="6" sm="6" class="d-flex justify-center">
              <v-img :src="require('@/assets/1.png')" contain :aspect-ratio="137/122" max-width="411"></v-img>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-card flat>
                <v-card-title>{{$t('m.product_title')}}</v-card-title>
                <v-card-text>
                  <p>{{$t('m.product_text_p1')}}</p>
                  <p>{{$t('m.product_text_p2')}}</p>
                  <p>{{$t('m.product_text_p3')}}</p>
                  <p>{{$t('m.product_text_p4')}}</p>
                  <p>{{$t('m.product_text_p5')}}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-responsive>
        </v-container>
      </section>
      <section id="features" class="pt-16">
        <v-container class="fill-height px-4 py-12">
          <v-responsive class="d-flex align-center mx-auto" height="100%" width="100%">
            <h1 class="text-center">{{$t('m.features_title')}}</h1>
            <v-row class="pt-16">
              <v-col cols="12" md="4" sm="4">
                <v-container>
                  <v-card>
                    <div class="d-flex justify-center featurebg"><v-icon color="white" x-large>mdi-head-snowflake</v-icon></div>
                    <v-card-title>{{$t('m.features_subtitle1')}}</v-card-title>
                    <v-card-text>
                      {{$t('m.features_subtext1')}}
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-container>
                  <v-card>
                    <div class="d-flex justify-center featurebg"><v-icon color="white" x-large>mdi-account-multiple-plus</v-icon></div>
                    <v-card-title>{{$t('m.features_subtitle2')}}</v-card-title>
                    <v-card-text>
                      {{$t('m.features_subtext2')}}
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-container>
                  <v-card>
                    <div class="d-flex justify-center featurebg"><v-icon color="white" x-large>mdi-shield-key</v-icon></div>
                    <v-card-title>{{$t('m.features_subtitle3')}}</v-card-title>
                    <v-card-text>
                      {{$t('m.features_subtext3')}}
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-responsive>
        </v-container>
      </section>
      <section id="advantage" class="pt-16">
        <v-container class="fill-height px-4 py-12">
          <v-responsive class="d-flex align-center mx-auto px-1" height="100%" width="100%">
            <h1 class="text-center">{{$t('m.advantage_title')}}</h1>
            <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown" class="mt-10">
              <v-timeline-item icon="mdi-numeric-1-circle" color="#3d3dce" fill-dot>
                <v-card color="#3d3dce">
                  <v-card-title class="white--text">{{$t('m.advantage_cardtitle1')}}</v-card-title>
                  <v-card-text class="white text--primary pt-4">{{$t('m.advantage_cardtext1')}}</v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="mdi-numeric-2-circle" color="#3d3dce" fill-dot>
                <v-card color="#3d3dce">
                  <v-card-title class="white--text">{{$t('m.advantage_cardtitle2')}}</v-card-title>
                  <v-card-text class="white text--primary pt-4">{{$t('m.advantage_cardtext2')}}</v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="mdi-numeric-3-circle" color="#3d3dce" fill-dot>
                <v-card color="#3d3dce">
                  <v-card-title class="white--text">{{$t('m.advantage_cardtitle3')}}</v-card-title>
                  <v-card-text class="white text--primary pt-4">{{$t('m.advantage_cardtext3')}}</v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="mdi-numeric-4-circle" color="#3d3dce" fill-dot>
                <v-card color="#3d3dce">
                  <v-card-title class="white--text">{{$t('m.advantage_cardtitle4')}}</v-card-title>
                  <v-card-text class="white text--primary pt-4">{{$t('m.advantage_cardtext4')}}</v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="mdi-numeric-5-circle" color="#3d3dce" fill-dot>
                <v-card color="#3d3dce">
                  <v-card-title class="white--text">{{$t('m.advantage_cardtitle5')}}</v-card-title>
                  <v-card-text class="white text--primary pt-4">{{$t('m.advantage_cardtext5')}}</v-card-text>
                </v-card>
              </v-timeline-item>
              <v-timeline-item icon="mdi-numeric-6-circle" color="#3d3dce" fill-dot>
                <v-card color="#3d3dce">
                  <v-card-title class="white--text">{{$t('m.advantage_cardtitle6')}}</v-card-title>
                  <v-card-text class="white text--primary pt-4">{{$t('m.advantage_cardtext6')}}</v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-responsive>
        </v-container>
      </section>
    </v-main>
    <v-footer height="100" dark>
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-icon class="mr-8">mdi-facebook</v-icon>
            <v-icon class="mr-8">mdi-twitter</v-icon>
            <v-icon class="mr-8">mdi-instagram</v-icon>
            <v-icon>mdi-whatsapp</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            &copy; FYLH 2021 | All right reserved.
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>
<script>

export default {
  data: () => ({
    lang: 0,
    drawer: false,
    group: null,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },

  computed: {
    minHeight () {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },
  },

  methods:{
    changeLang(){
      switch (this.lang) {
          case 0:
              this.$i18n.locale = 'en'
              break
          case 1:
              this.$i18n.locale = 'id'
              break
          case 2:
              this.$i18n.locale = 'zhHant'
              break
          default:
              this.$i18n.locale = 'en'
              break;
      }
    }
  }
};
</script>
<style>
.v-btn {
  text-transform: none !important;
}
.featurebg{
  height: 60px;
  background-color: #3d3dce;
}
</style>